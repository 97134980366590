<template>
	<div class="wrapper d-flex flex-column align-items-center justify-content-center">
		<div class="bg-slant"></div>
		<div class="auth-form-container">
			<div>
				<div class="h3 mb-4">
					Create Your Account
				</div>
				<b-form>
					<b-form-group id="input-group-1">
						<label for="inline-form-input-email" class="mb-2">
							Email
						</label>
						<b-form-input id="inline-form-input-email" class="mb-2" placeholder="Email" v-model="email"></b-form-input>
					</b-form-group>

					<b-form-group id="input-group-2">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<label class="m-0" for="inline-form-input-password">
								Password
							</label>
							<a href="#" style="font-size: 0.625rem;" class="text-muted">Forgot your password</a>
						</div>
						<b-form-input id="inline-form-input-password" placeholder="Password" v-model="password" type="password"></b-form-input>
					</b-form-group>

					<b-form-group id="input-group-3" class="m-0">
						<b-button class="w-100" variant="primary" @click.prevent="register">Create My Account</b-button>
					</b-form-group>
				</b-form>
				<b-alert variant="danger" class="mt-4" show v-if="error">{{ error }}</b-alert>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	data () {
		return {
			email: '',
			password: '',
			error: false,
			askRegister: false
		}
	},
	methods: {
		register () {
			const { email, password } = this;
			const {
				User
			} = this.$FeathersVuex.api
			new User({
				email,
				password
			})
			.save()
			.then(this.login)
			// Just use the returned error instead of mapping it from the store.
			.catch(err => {
				// Convert the error to a plain object and add a message.
				if (err.data ) {
					let type = err.data.code;
					err = Object.assign({}, err);
					err.message =
						type === 11000 ?
						'That email address is unavailable.' :
						'An error prevented signup.'
				}
				this.error = err.message;
			});
		},
		login () {
			const { email, password } = this;
			this.$store.dispatch('auth/authenticate', {
				strategy: 'local',
				email,
				password
			})
			.then(() => {
				this.$router.push('/');
			}).catch(err => { this.error = err.message });
		}
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	margin: 6rem auto;
}
.auth-form-container {
	background-color: var(--color-white);
	width: 400px;
	padding: 2.5rem;
	box-shadow: 0 4px 20px rgba(0,0,0,0.09);
	border-radius: 1rem;
	z-index: 10;
}
.bg-slant {
	width: 120vw;
	height: 350px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -3rem) rotate(-5deg);
	background-color: #FDEAE3;
	background-image: linear-gradient(to right, #FDEAE3, #FEE2EF);
}
</style>
